<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br />
    <div class="container fluid">
      <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/board_handling_system/loading_unloading/magazine_loader_standard">
          <div class="card" style="width:260px;height:180px;">
          
            <img
              style="max-height:80%; max-width:80%;"
              class="card-img-top img-fluid"
              src="../../../../../assets/images/product/main/production_equipement/Bord_handling_system/Loading_Unloading/MAGAZINE LOADER (Standard Type).jpg"
              alt="Card image cap"
            />
            
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                MAGAZINE LOADER (Standard Type)
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/board_handling_system/loading_unloading/magazine_loader_90">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:80%;"
              class="card-img-top img-fluid"
              src="../../../../../assets/images/product/main/production_equipement/Bord_handling_system/Loading_Unloading/MAGAZINE LOADER (90˚ Type).jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                MAGAZINE LOADER (90˚ Type)
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/board_handling_system/loading_unloading/magazine_loader_ergonomic">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:80%;"
              class="card-img-top img-fluid"
              src="../../../../../assets/images/product/main/production_equipement/Bord_handling_system/Loading_Unloading/MAGAZINE LOADER (Ergonomic Type).jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                MAGAZINE LOADER (Ergonomic Type)
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link
           to="/production_equipement/board_handling_system/loading_unloading/bare_board_loader">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:80%;"
              class="card-img-top img-fluid"
              src="../../../../../assets/images/product/main/production_equipement/Bord_handling_system/Loading_Unloading/BARE BOARD LOADER(VACUUM PICK-UP TYPE).jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                BARE BOARD LOADER(VACUUM PICK-UP TYPE)
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>
      <div class="row" style="margin-top: 30px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/board_handling_system/loading_unloading/magazine_vacuum_loader">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:80%;"
              class="card-img-top img-fluid"
              src="../../../../../assets/images/product/main/production_equipement/Bord_handling_system/Loading_Unloading/MAGAZINE & VACUUM LOADER(Combination).jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                MAGAZINE & VACUUM LOADER(Combination)
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/board_handling_system/loading_unloading/single_magazine_loader">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:80%;"
              class="card-img-top img-fluid"
              src="../../../../../assets/images/product/main/production_equipement/Bord_handling_system/Loading_Unloading/SINGLE MAGAZINE LOADER (CE Type).jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
               SINGLE MAGAZINE LOADER (CE Type)
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/board_handling_system/loading_unloading/dual_magazine_loader">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:80%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../../../../assets/images/product/main/production_equipement/Bord_handling_system/Loading_Unloading/DUAL MAGAZINE LOADERUNLOADER(CE Type).jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
               DUAL MAGAZINE LOADER/UNLOADER(CE Type)
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/board_handling_system/loading_unloading/magazine_unloader_standard">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:80%;"
              class="card-img-top img-fluid"
              src="../../../../../assets/images/product/main/production_equipement/Bord_handling_system/Loading_Unloading/MAGAZINE UNLOADER (Standard Type).jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                MAGAZINE UNLOADER (Standard Type)
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>
       <div class="row" style="margin-top: 30px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/board_handling_system/loading_unloading/magazine_unloader_90">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:80%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../../../../assets/images/product/main/production_equipement/Bord_handling_system/Loading_Unloading/MAGAZINE UNLOADER (90˚ Type).jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
               MAGAZINE UNLOADER (90˚ Type)
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/board_handling_system/loading_unloading/magazine_unloader_ergonomic">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:80%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../../../../assets/images/product/main/production_equipement/Bord_handling_system/Loading_Unloading/MAGAZINE UNLOADER (Ergonomic Type).jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
               MAGAZINE UNLOADER (Ergonomic Type)
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/board_handling_system/loading_unloading/single_magazine_unloader">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:80%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../../../../assets/images/product/main/production_equipement/Bord_handling_system/Loading_Unloading/SINGLE MAGAZINE UNLOADER (CE Type).jpg"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                SINGLE MAGAZINE UNLOADER (CE Type)
              </h4>
            </div>
          </div>
          </router-link>
        </div>
       
      </div>
    
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../../components/Header.vue";

import Footer from "../../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
};
</script>